<template>
    <div>
        <b-card border-variant="danger" v-if="errore_caricamento">
          <b-row class="match-height">
            <b-col
              md="8"
            >
              <h4 class="mt-0 mb-0 text-danger">Errore durante il caricamento delle informazioni iniziali</h4>
              <p>Ti preghiamo di segnalare il problema all'assistenza tecnica, grazie</p>
            </b-col>
            <b-col 
              md="4"
              class="text-center"
            >
              <b-button
              variant="primary"
              :to="{ name: 'crm-utenti-index' }"
              >
                  <feather-icon
                      icon="CornerUpLeftIcon"
                      size="16"
                      class="mr-1"
                  />Lista Utenti
              </b-button>
            </b-col>
          </b-row>
            
        </b-card>

        <div class="text-center my-3" v-if="Caricato == false">
            <b-spinner  v-if="errore_caricamento == false"
                variant="primary"
                style="width: 3rem; height: 3rem;"
                label="loading"
            />
            <div class="pt-1 text-primary" style="display:block;">Caricamento</div>
        </div>

        <div v-if="Caricato">
            <b-card>
                <b-card-header class="pt-0">
                    <b-card-title>
                    {{ campiform.name }}
                    <b-badge variant="light-success" class="ml-1" v-if="campiform.active === true">Attivo</b-badge>
                    <b-badge variant="light-danger" class="ml-1" v-if="campiform.active !== true">Disattivo</b-badge>
                    </b-card-title>
                    <b-button
                        variant="primary"
                        class="btn-icon rounded-circle"
                        id="`sidebar-azioni`"
                        @click="sidebarActionShow(campiform.id, campiform.active, campiform.name)"
                    >
                        <feather-icon icon="ArrowRightIcon" />
                    </b-button>
                </b-card-header>

                <b-card-body class="px-0 py-0">
                    <b-tabs pills>
                        <b-tab>
                            <template #title>
                                <feather-icon
                                icon="SendIcon"
                                size="16"
                                class="mr-0 mr-sm-50"
                                />
                                <span class="d-none d-sm-inline">Opzioni Template</span>
                            </template>
                            <b-card bg-variant="Default">
                                <b-row>
                                <b-col lg="12">
                                    <showdata field_name="Oggetto" :field_value="campiform.subject" />
                                </b-col>
                                
                                <b-col lg="6">
                                    <showdata field_name="Description" :field_value="campiform.description" />
                                </b-col>
                                <b-col lg="6">
                                    <showdata field_name="Categoria" :field_value="campiform.cat.name" />
                                </b-col>

                                <b-col lg="6">
                                    <showdata field_name="Preheader" :field_value="campiform.preheader" />
                                </b-col>
                                <b-col lg="6">
                                    <showdata field_name="Sender" :field_value="campiform.sender" />
                                </b-col>
                                
                                </b-row>

                            </b-card>
                        </b-tab>

                        <b-tab>
                            <template #title>
                                <feather-icon
                                icon="MailIcon"
                                size="16"
                                class="mr-0 mr-sm-50"
                                />
                                <span class="d-none d-sm-inline">Contenuto Template</span>
                            </template>

                            <b-card bg-variant="Default">
                                <div class="ql-container ql-snow">
                                    <div class="ql-editor">
                                        <div v-html="campiform.text"></div>
                                    </div>
                                </div>
                                
                            </b-card>
                        </b-tab>
                    </b-tabs>

                </b-card-body>
            </b-card>
        </div>

        <b-sidebar
        ref="sidebar_azioni"
        id="sidebar-right"
        bg-variant="white"
        :title="infoSideBar.name"
        right
        backdrop
        shadow
        >
        <div>
            <div class="py-0 px-2">
                <div class="divider">
                    <div class="divider-text text-primary fw-bolder mt-2">AZIONI</div>
                </div>
                
                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-warning" block
                    class="mb-1"
                    @click="$router.push({ name: 'tools-emailtemplate-edit', params: { id_riga: infoSideBar.id }})"
                >
                    <feather-icon icon="Edit2Icon" /> Modifica
                </b-button>
                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-warning" block
                    class="mb-1"
                    @click="$router.push({ name: 'tools-emailtemplate-duplic', params: { id_riga: infoSideBar.id }})"
                >
                    <feather-icon icon="CopyIcon" /> Duplica
                </b-button>
                
                <div v-if="infoSideBar.stato === true">
                    <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-warning" block
                    class="mb-1"
                    @click="aggiornaStato(infoSideBar.id,false)"
                    >
                    <feather-icon icon="SlashIcon" /> Disattiva template
                    </b-button>
                </div>
                <div v-else>
                    <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary" block
                    class="mb-1"
                    @click="aggiornaStato(infoSideBar.id,true)"
                    >
                    <feather-icon icon="CheckCircleIcon" /> Attiva template
                    </b-button>
                </div>

                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-danger" block
                    class="mb-1"
                    @click="cancellaRiga(infoSideBar.id)"
                >
                    <feather-icon icon="Trash2Icon" /> Cancella
                </b-button>
            </div>

        </div>
        </b-sidebar>
          
    </div>
</template>

<script>
import router from '@/router'
import { BTabs, BTab, BRow, BCol, BCard, BCardTitle, BCardHeader, BCardBody, BCardText, BSpinner, BButton, BSidebar, BBadge } from 'bootstrap-vue'
import showdata from '@core/components/showdata/showdata.vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BTabs,
    BTab,
    BRow,
    BCol,
    BCard, 
    BCardTitle, 
    BCardHeader, 
    BCardBody, 
    BCardText, 
    BSpinner, 
    BButton,
    BSidebar, 
    BBadge,

    showdata,   
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      campiform: {
        name: '',
        description: '',
        sender: '',
        subject: '',
        preheader: '',
        text: ``,
        category_id: '',
        active: false
      },
      options_categorie: [ ],
      Caricato: false,
      errore_caricamento: false,
      infoSideBar: {
        id: '',
        stato: '',
        name: '',
      },
    }
  },
  created() {
    const categoriesPromise = this.$http.get('api/crm/emailtemplate/catlist')    
    const infoEmailtemplatePromise = this.$http.get('api/crm/emailtemplate/card/'+router.currentRoute.params.id_riga)

    Promise.all([categoriesPromise, infoEmailtemplatePromise]).then(response => {
      if(response[0].data.statusCode===200){
        //caricamento lista categorie
        this.options_categorie = response[0].data.reply

        if(response[1].data.statusCode===200){
            //form dati email template
            this.campiform = response[1].data.reply.data

            //fine
            this.Caricato = true;
            this.errore_caricamento = false;
        } else {
            this.Caricato = false;
            this.errore_caricamento = true;
        }

      } else {
        this.Caricato = false;
        this.errore_caricamento = true;
      }
        
    })
  },
  methods: {
    sidebarActionShow(id, active, name) {
      this.infoSideBar = { id: id, stato: active, name: name };

      this.$root.$emit('bv::toggle::collapse', 'sidebar-right')
    },cancellaRiga(id_riga){
      this.$swal({
        title: 'Sei sicuro di voler procedere?',
        text: "conferma la cancellazione definitiva del Cliente?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'SI, Procedi',
        cancelButtonText: 'Annulla operazione',
        customClass: {
          confirmButton: 'btn btn-outline-danger',
          cancelButton: 'btn btn-primary ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {

            this.$http.post('api/crm/emailtemplate/delete/'+id_riga)
            .then(response => { 
                if(response.data.statusCode===200){
                  //risposta positiva
                  this.$router.replace('/tools/emailtemplate')
                    .then(() => {
                        this.$swal({
                            icon: 'success',
                            title: response.data.reply.reply,
                            confirmButtonText: 'chiudi',
                            customClass: {
                            confirmButton: 'btn btn-success',
                            },
                        })
                    })                   
                    
                } else {
                //risposta negativa (errore sul server)
                  this.$swal({
                      icon: 'error',
                      title: 'Si è verificato un errore',
                      text: 'operazione non riuscita, ti preghiamo di contattate l\'assistenza tecnica per segnalare il problema',
                      customClass: {
                      confirmButton: 'btn btn-outline-primary',
                      },
                  })
                }
            }).catch(e => {
                console.log(e);
            })
        }
      })
    },
    aggiornaStato(id_riga,stato_boolean){
      let stato = '';
      let messaggio = '';
      let icon = '';
      let confirmButton = '';

      if(stato_boolean === true){
        stato+=1;
        messaggio+='confermi l\'attivazione del template selezionato?';
        icon+='success';
        confirmButton+='btn btn-outline-success';
      } else {
        stato+=0;
        messaggio+='confermi la disattivazione del template selezionato?';
        icon+='warning';
        confirmButton+='btn btn-outline-warning';
      }

      console.log("id: "+id_riga+" | stato: "+stato)

      this.$swal({
        title: 'Sei sicuro di voler procedere?',
        text: messaggio,
        icon: icon,
        showCancelButton: true,
        confirmButtonText: 'SI, Procedi',
        cancelButtonText: 'Annulla operazione',
        customClass: {
          confirmButton: confirmButton,
          cancelButton: 'btn btn-primary ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {

            this.$http.post('api/crm/emailtemplate/cambiostato/'+id_riga+'/'+stato)
            .then(response => { 
                if(response.data.statusCode===200){
                  //risposta positiva
                  this.$swal({
                    icon: 'success',
                    title: response.data.reply.reply,
                    //text: 'operazione effettuata correttamente',
                    confirmButtonText: 'chiudi',
                    customClass: {
                    confirmButton: 'btn btn-success',
                    },
                  })

                  //chiudi sidebar
                  this.$refs.sidebar_azioni.hide();

                  //aggiorna tabella
                  if(stato==1){
                    this.campiform.active = true
                  } else {
                    this.campiform.active = false
                  }
                    
                } else {
                //risposta negativa (errore sul server)
                  this.$swal({
                      icon: 'error',
                      title: 'Si è verificato un errore',
                      text: 'operazione non riuscita, ti preghiamo di contattate l\'assistenza tecnica per segnalare il problema',
                      customClass: {
                      confirmButton: 'btn btn-outline-primary',
                      },
                  })
                }
            }).catch(e => {
                console.log(e);
            })
        }
      })
    },
  },
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-sweetalert.scss';
@import '@core/scss/vue/libs/quill.scss';
</style>
